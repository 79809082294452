<template>
  <div>
    <!-- wrapper -->
    <div class="wrapper">
      <Navigation />
      <!--navigation-->
      <!--end navigation-->
      <!--page-wrapper-->
      <div class="page-wrapper">
        <!--page-content-wrapper-->
      <div class="container-fluid">
        <!-- <SideNav /> -->
        <div class="card radius-15">
          <div class="card-body">
            <div class="page-breadcrumb d-md-flex align-items-center">
              <div
                class="btn-group m-1 ml-2"
                role="group"
                aria-label="Basic example"
              >
                <a :href="homeLink" type="button" class="btn btn-primary">
                  <i class="bx bx-home-alt"></i>
                </a>
                <a :href="betHistoryLink" type="button" class="btn btn-primary">
                  <i class="bx bx-dna"></i>
                </a>
              </div>
              <button
                type="button"
                class="btn m-1"
                v-for="expiryTime in expirations"
                :class="[
                  expiry.value == expiryTime.value
                    ? 'btn-primary'
                    : 'btn-light-primary',
                ]"
                @click="changeExpiry(expiryTime)"
              >
                {{ expiryTime.label }}
              </button>

              <div class="ml-auto">
                <div class="btn-group">
                  <!-- <button type="button" class="btn btn-primary m-1 px-5"><i class="bx bx-wallet-alt"></i> Deposites</button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        
          <div class="card radius-15">
            <div class="card-body">
              <div class="row">
                <div class="col-md-9">
                  <div class="card">
                    <div
                      class="card-body"
                      style="overflow-x: clip; text-align: center"
                    >
                      <div class="chart-container chart-container-width">
                        <canvas class="chart-canvas" ref="chart"></canvas>
                        <div
                          :style="`top: calc(${chartTop}px + ${
                            verticalPos - 8
                          }px);`"
                          style="
                            position: absolute;
                            width: 84%;
                            border-top: 2px dashed rgb(60, 125, 231);
                            margin-left: 70px;
                          "
                        ></div>
                        <div
                          class="current-price-label-wrapper"
                          :style="`top: calc(${chartTop}px + ${
                            verticalPos - 15
                          }px);`"
                        >
                          <div class="current-price-triangle"></div>
                          <p class="current-price-label">
                            ${{ lastPrice.toFixed(2) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="input-group">
                    <div class="input-group-btn">
                      <button
                        id="down"
                        class="btn btn-outline-primary"
                        @click="decrementStake"
                      >
                        <i class="bx bx-minus icon_test"></i>
                      </button>
                    </div>
                    <input
                      type="text"
                      class="form-control input-number"
                      v-model="stake"
                      :disabled="tradeIsOpen"
                    />
                    <div class="input-group-btn">
                      <button
                        id="up"
                        class="btn btn-outline-primary"
                        @click="incrementStake"
                      >
                        <i class="bx bx-plus icon_test"></i>
                      </button>
                    </div>
                  </div>

                  <div class="row progressBarPadding">
                    <div class="col-md-12">
                      <label>
                        <div v-show="message" class="message">
                          {{ message }}
                        </div>
                        <p v-if="error" class="error-message">{{ error }}</p>
                      </label>
                      <div
                        v-show="secondsToExpiry > 0"
                        class="progress"
                        style="height: 7px"
                      >
                        <div
                          class="progress-bar bg-info"
                          role="progressbar"
                          :style="{
                            width: (secondsToExpiry / duration) * 100 + '%',
                          }"
                          aria-valuenow="75"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button
                        type="button"
                        class="btn btn-success m-1 btn-lg btn-block px-5 pt-4 pb-4"
                        @click="tradeIsOpen || !canTrade ? null : trade(1)"
                      >
                        <i class="bx bx-trending-up mr-1"></i>{{$t('Buy Call')}}
                        <!-- <h5>9.8750</h5> -->
                      </button>
                      <!-- <span class="ml-1 text-success"
                        >Lorem ipsum dolor sit amet consectetur adipisicing
                        elit.</span
                      > -->
                    </div>
                    <div class="col-md-12 mt-4">
                      <button
                        type="button"
                        class="btn btn-danger m-1 btn-lg btn-block px-5 pt-4 pb-4"
                        @click="tradeIsOpen || !canTrade ? null : trade(-1)"
                      >
                        <i class="bx bx-trending-down mr-1"></i>{{$t('Buy Put')}}
                        <!-- <h5>8.8710</h5> -->
                      </button>
                      <!-- <span class="ml-1 text-danger"
                        >Lorem ipsum dolor sit amet consectetur adipisicing
                        elit.</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="card radius-15">
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-md-3 col-12">
                <label
                  ><b>{{ $t("BetAmount") }}</b></label
                >
                <div class="input-group">
                  <div class="input-group-btn">
                    <button
                      id="down"
                      class="btn btn-outline-primary"
                      @click="decrementStake"
                    >
                      <i class="bx bx-minus icon_test"></i>
                    </button>
                  </div>
                  <input
                    type="text"
                    class="form-control input-number"
                    v-model="stake"
                    :disabled="tradeIsOpen"
                  />
                  <div class="input-group-btn">
                    <button
                      id="up"
                      class="btn btn-outline-primary"
                      @click="incrementStake"
                    >
                      <i class="bx bx-plus icon_test"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-2 col-12">
                <label
                  ><b>{{ $t("BetDuration") }}</b></label
                >
                <vue-select
                  style="height: 39px"
                  v-model="expiry"
                  :options="expirations"
                  :clearable="false"
                  :value="expiry"
                  :disabled="tradeIsOpen"
                ></vue-select>
              </div>
              <div class="col-md-5 mt-4" style="text-align: center">
                <label>
                  <div v-show="message" class="message">
                    {{ message }}
                  </div>
                  <p v-if="error" class="error-message">{{ error }}</p>
                </label>
                <div
                  v-show="secondsToExpiry > 0"
                  class="progress"
                  style="height: 7px"
                >
                  <div
                    class="progress-bar bg-info"
                    role="progressbar"
                    :style="{ width: (secondsToExpiry / duration) * 100 + '%' }"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div class="col-md-2 form-group mt-4">
                <div class="float-right pull-right">
                  <button
                    type="button"
                    class="btn btn-primary m-1"
                    @click="tradeIsOpen || !canTrade ? null : trade(1)"
                  >
                    <i class="bx bx-arrow-from-bottom"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger m-1"
                    @click="tradeIsOpen || !canTrade ? null : trade(-1)"
                  >
                    <i class="bx bx-arrow-from-top"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!--end page-content-wrapper-->
      </div>
      <!--end page-wrapper-->
      <!--start overlay-->
      <div class="overlay toggle-btn-mobile"></div>
      <!--end overlay-->
      <!--Start Back To Top Button-->
      <a href="javascript:;" class="back-to-top"
        ><i class="bx bxs-up-arrow-alt"></i
      ></a>
      <!--End Back To Top Button-->
      <!--footer -->
      <Footer />
      <!-- end footer -->
    </div>
    <!-- end wrapper -->
    <!--start switcher-->
    <!-- <ThemeSettings /> -->
    <!--end switcher-->
  </div>
</template>

<script type="text/babel">
import Navigation from "./Navigation.vue";
import SideNav from "./SideNav.vue";
import Footer from "./Footer.vue";
// import ThemeSettings from "./ThemeSettings.vue";
// import styles from '../../../scss/style.scss'
import numeral from "numeral";
import assets from "../static/assets.json";
import * as nf from "../modules/numberFormat";
import * as cf from "../modules/colorFormat";
import Chart from "chart.js";
import RestApi from "../modules/restApi";
import WsApi from "../modules/websocketApi";
// import "vue-select/dist/vue-select.css";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import store from "../store";
import constants from "./../modules/constants";
var CryptoJS = require("crypto-js");

// numeral.register("locale", "custom", {
//   delimiters: {
//     decimal: ".",
//     thousands: ",",
//   },
//   ordinal: function (number) {
//     return "";
//   },
//   currency: {
//     symbol: "$",
//   },
// });
// numeral.locale("custom");

export default {
  name: "Game",
  components: {
    Navigation,
    SideNav,
    Footer,
    // ThemeSettings,
  },
  mixins: [],
  data() {
    return {


      homeLink: this.$i18n.locale+"/game/" + this.encryptToken(),
      betHistoryLink: this.$i18n.locale+"/betHistory/",


      sideNavProps: { selected: "home" },
      config: {
        id: "cryptocurrency-price-prediction-game",
        code: "cppg",
        historyLength: 20,
        thousandsSeparator: ",",
        decimalSeparator: ".",
        locale: "en-US",
        text: {},
      },

      userID: "",
      balances: null,
      history: {
        gamesPlayed: 0,
      },
      error: null,
      currentGameID: null,

      color: "rgba(46,74,159,1)",
      asset: null,
      expiry: null,
      secondsToExpiry: 0,
      stake: 0,
      return: this.$attrs.return ? parseFloat(this.$attrs.return) : 0.8,
      quotes: [],
      chart: null,
      restApi: null,
      wsApi: null,
      message: null,
      defaultUserData: {
        balance: 1000,
        wins: 0,
        losses: 0,
        trade: null,
      },
      userData: null,
      timeout: null,
      interval: null,
      currentPricePosY: 0,
      chartTop: null,
      chartBottom: null,
    };
  },
  computed: {
    // getting the user data after login
    // getting 2 key "authenticated(boolean)" and "user(object)"
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),

    assets() {
      return assets;
    },
    expirations() {
      return [
        { value: 5, label: this.__("5 sec") },
        { value: 15, label: this.__("15 sec") },
        { value: 30, label: this.__("30 sec") },
        { value: 45, label: this.__("45 sec") },
        { value: 60, label: this.__("1 min") },
        { value: 120, label: this.__("2 mins") },
        { value: 300, label: this.__("5 mins") },
        // { value: 600, label: this.__("10 mins") },
        // { value: 900, label: this.__("15 mins") },
        // { value: 1800, label: this.__("30 mins") },
        // { value: 3600, label: this.__("1 h") },
      ];
    },
    dates() {
      return this.quotes.map((quote) => quote.date);
    },
    prices() {
      return this.quotes.map((quote) => quote.price);
    },
    balance() {
      if (!this.balances) return 0;
      let asset = this.balances.find(
        (coin) => coin.currencySymbol === this.asset.symbol
      );
      if (asset) return asset.gameBalance;
      else return 0;
    },
    _balance() {
      return nf.decimal(this.balance) + " " + this.asset.symbol;
    },
    _payout() {
      return this.stake > 0
        ? nf.decimal(this.stake * (1 + this.return)) +
            " " +
            this.asset.symbol +
            " (" +
            nf.percentage(this.return * 100) +
            ")"
        : "";
    },
    lastPrice() {
      var n = this.quotes.length;
      return n > 0 ? this.quotes[this.quotes.length - 1].price : 0;
    },
    _lastPrice() {
      return nf.variableDecimal(this.lastPrice);
    },
    duration() {
      return this.userData.trade
        ? this.userData.trade.expiry - this.userData.trade.date
        : 0;
    },
    _lastPriceIntegerPart() {
      return nf.integer(Math.floor(this.lastPrice));
    },
    _lastPriceDecimalPart() {
      var string = this.lastPrice + "";
      var n = string.indexOf(".");
      return n > -1 ? string.substring(n + 1, n + 9) : "";
    },
    tradeIsOpen() {
      return this.userData.trade !== null;
    },
    canTrade() {
      return (
        !this.tradeIsOpen &&
        this.stake >= 0 &&
        this.userData.balance >= this.stake &&
        this.lastPrice > 0
      );
    },
    verticalPos() {
      return this.currentPricePosY;
    },
  },
  methods: {

    encryptToken() {
      var re = new RegExp("/", "g");
      return CryptoJS.AES.encrypt(
        localStorage.getItem("token"),
        constants.CRYPTOJSKEY
      )
        .toString()
        .replace(re, "_");
    },
    // display chart
    displayChart() {
      // if the chart is already initialized update the chart data
      if (this.chart !== null) {
        this.chart.config.data.labels = this.dates;
        this.chart.config.data.datasets[0].data = this.prices;
        this.chart.update();
        this.updateUI();
        return;
      }

      var chartData = {
        labels: this.dates,
        datasets: [
          {
            data: this.prices,
            borderColor: "rgb(46,74,159)",
            borderWidth: 1,
            backgroundColor: cf.gradient(
              this.$refs.chart,
              "top to bottom",
              300,
              [
                cf.shadeBlend(0.1, this.color, "rgba(46,74,159,0)"),
                cf.shadeBlend(0.4, this.color, "rgba(46,74,159,0)"),
                cf.shadeBlend(0.7, this.color, "rgba(46,74,159,0)"),
                cf.shadeBlend(0.9, this.color, "rgba(46,74,159,0)"),
              ]
            ),
            radius: 0,
            hoverRadius: 5,
            hitRadius: 0,
            pointRadius: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4,
            ],
            keepTooltipOpen: true,
          },
        ],
      };

      var chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            right: 120,
          },
        },
        hover: {
          mode: "nearest",
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false, // display tooltip at all times
          cornerRadius: 6,
          titleFontColor: "#fff",
          bodyFontSize: 14,
          displayColors: false,
          bodyFontColor: "#fff",
          backgroundColor: "rgb(46,74,159)",
          borderColor: this.color,
          borderWidth: 0,
          xPadding: 16,
          yPadding: 16,
          bodySpacing: 5,
          callbacks: {
            label: (tooltipItem, data) => {
              return (
                this.asset.name + " $" + nf.variableDecimal(tooltipItem.yLabel)
              );
            },
          },
        },
        elements: {
          line: {
            tension: 0.4,
          },
          point: {
            radius: 3,
          },
        },
        legend: {
          display: false,
        },
        animation: {
          duration: 300,
          easing: "easeInOutSine",
          onComplete: () => {
            // this.updateUI();
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              ticks: {
                maxRotation: 70,
                padding: 5,
                fontColor: "rgb(46,74,159)",
                callback: (value, index, values) => {
                  // if ((index + 1) % 5 === 0) {

                  let hours = new Date(value).getHours();
                  let min = new Date(value).getMinutes();
                  return hours + ":" + min;
                  console.log("value ", hours, ":", min);
                  return new Date(value).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  });
                  // }
                  // else return;
                },
              },
              gridLines: {
                display: true,
                drawBorder: false,
                color: "rgba(46,74,159,0.2)",
              },
            },
          ],
          yAxes: [
            {
              // position: 'right',
              display: true,
              ticks: {
                fontColor: "rgb(46,74,159)",
                callback: (value, index, values) => "$" + value,
              },
              gridLines: {
                display: true,
                drawBorder: false,
                color: "rgba(46,74,159,0.2)",
              },
            },
          ],
        },
      };

      this.chart = new Chart(this.$refs.chart, {
        type: "line",
        data: chartData,
        options: chartOptions,
      });
    },
    // update chart and live data when asset is changed
    updateAsset() {
      this.quotes = [];
      this.restApi.getHistory(
        this.asset.id,
        "m1",
        this.config.historyLength,
        this.config.historyLength
      );
      this.wsApi.subscribe(this.asset.id);
    },
    // make a new trade
    trade(direction) {
      var now = Math.round(new Date().getTime() / 1000);
      var expiry = parseInt(this.expiry.value);

      this.userData.balance -= this.stake; // decrease user balance

      this.userData.trade = {
        date: now,
        expiry: now + expiry,
        direction: direction,
        stake: this.stake,
        price: this.lastPrice,
      };

      // console.log(this.userData.trade);
      // console.log("token ", localStorage.getItem("token"));

      axios({
        method: "post",
        url: "api/game/gameStake",
        data: {
          userId: this.userID,
          amount: this.stake,
          direction: direction,
          asset: this.asset.symbol,
          trade_price: this.lastPrice,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        // console.log("api/game/gameStake",response);
        if (!response.data.status) {
          this.error = response.data.message;
          this.userData.trade = null;
          this.saveUserData();
        } else {
          this.error = null;
          this.currentGameID = response.data.data._id;
          this.secondsToExpiry = this.expiry.value;
          this.setCheckTradeExpiryInterval();
          this.saveUserData();
        }
      });

      clearTimeout(this.timeout); // if trade is started before win / lose message disappears
    },
    // set interval for checking trade expiry
    setCheckTradeExpiryInterval() {
      this.message =
        this.__(this.$t("Open price")) + ": $" + this.userData.trade.price;
      // check trade for expiration every second
      this.interval = setInterval(() => this.checkTradeExpiry(), 200);
    },
    // check if trade is expired
    checkTradeExpiry() {
      var trade = this.userData.trade;
      this.secondsToExpiry = trade.expiry - Date.now() / 1000;

      // trade is expired
      if (this.secondsToExpiry <= 0) {
        clearInterval(this.interval);

        var priceDifference = (this.lastPrice - trade.price) * trade.direction;

        if (priceDifference > 0) {
          var payout = trade.stake * (1 + this.return);
          // this.message = this.__(this.$t("You win")) + " $" + payout;
          this.$toastr.Add({
            type: "success",
            msg: this.$t("You win") + " $" + payout,
            position: "toast-bottom-right",
          });
          this.userData.balance += payout;
          this.userData.wins++;
        } else {
          // this.message = this.__("You lose");
          this.$toastr.Add({
            type: "error",
            msg: this.$t("You lose"),
            position: "toast-bottom-right",
          });
          this.userData.losses++;
        }

        // console.log(this.currentGameID);

        let direction = priceDifference > 0 ? 1 : -1;
        // if (priceDifference === 0) direction = 0;

        axios({
          method: "post",
          url: "api/game/winOrLose",
          data: {
            userId: this.userID,
            asset: this.asset.symbol,
            direction: direction,
            gameId: this.currentGameID,
            last_price: this.lastPrice,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }).then((response) => {
          // console.log(response);
          if (!response.data.status) {
            this.error = response.data.message;
          }
          this.fetchBalance();
        });

        // clear the message in 5 seconds
        this.timeout = setTimeout(() => {
          this.message = null;
        }, 5000);

        this.userData.trade = null;

        this.saveUserData();
      }
    },
    // save user data to browser localStorage
    saveUserData() {
      localStorage.setItem(this.config.code, JSON.stringify(this.userData));
    },
    // translate string
    __(string) {
      return this.config.text[string] || string;
    },
    updateUI() {
      let data = this.chart.getDatasetMeta(0).data;
      let lastPointPosition = data[data.length - 1];
      if (!lastPointPosition) return;
      this.currentPricePosY = lastPointPosition._model.y;

      let chartScale = this.chart.getDatasetMeta(0).dataset._scale;
      if (!chartScale) return;
      this.chartTop = chartScale.top;
      this.chartBottom = chartScale.bottom;
    },
    incrementStake() {
      this.stake = (parseFloat(this.stake) + 0.01).toFixed(2);
    },
    decrementStake() {
      if (this.stake > 0) {
        this.stake = (parseFloat(this.stake) - 0.01).toFixed(2);
      }
    },

    checkCoinImage(symbol) {
      try {
        let imagePath = require(`@/assets/coins/${symbol}.png`);
        if (imagePath) return true;
      } catch (error) {
        return false;
      }
    },

    fetchBalance() {
      axios({
        method: "post",
        url: "api/address/balance",
        data: { wallet_type: "BTC" },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.balances = response.data.data;
        // console.log("get balance:", this.balances);
      });
    },
    changeExpiry(expiryTime) {
      this.expiry = expiryTime;
    },
  },
  created() {
    // this.$toastr.s("SUCCESS MESSAGE", "Success Toast Title", {
    //   position: "toast-bottom-left"
    // });
    this.userID = localStorage.getItem("userId");
    Chart.plugins.register({
      afterDraw: (chart) => {
        // console.log(chart.config.data.datasets);
        if (chart && chart.config.data.datasets[0].data.length == 0) {
          // No data is present
          var ctx = chart.chart.ctx;
          var width = chart.chart.width;
          var height = chart.chart.height;
          chart.clear();

          ctx.save();
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "20px Arial";
          ctx.fillStyle = this.color;
          ctx.fillText(
            this.__("No historical data is available for this asset"),
            width / 2,
            height / 2
          );
          ctx.restore();
        }
      },
    });

    // init asset and expiry dropdowns
    this.asset = this.assets[parseInt(this.$attrs.asset) || 166]; // this will trigger updateAsset() function
    this.expiry = this.expirations[1];

    var userData = localStorage.getItem(this.config.code);
    this.userData = userData ? JSON.parse(userData) : this.defaultUserData;

    this.$on("quote", (quote) => {
      if (this.chart && this.quotes.length) {
        this.quotes.push({ date: new Date().getTime(), price: quote });

        if (this.quotes.length > this.config.historyLength) this.quotes.shift();

        this.displayChart();
      }
    });

    this.restApi = new RestApi({
      onHistory: (history) => {
        // console.log("history ", history);
        this.quotes = history;
        this.displayChart();
      },
    });

    // this.restApi.getHistory(
    //   this.asset.id,
    //   "m1",
    //   this.config.historyLength,
    //   this.config.historyLength
    // );

    this.wsApi = new WsApi({
      onMessage: (quote) => this.$emit("quote", quote),
    });

    this.updateAsset();
  },
  mounted() {
    if (this.userData.trade) {
      this.setCheckTradeExpiryInterval();
      this.checkTradeExpiry();
    }

    if (this.userID) {
      axios({
        method: "post",
        url: "api/game/showGameStats",
        data: {},
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        // console.log("game stats:", response);
      });
      this.fetchBalance();
    }
  },
};
</script>


<style scoped>
@media (max-width: 1800px) {
  .chart-container-width {
    width: 109%;
  }
}
@media (max-width: 1200px) {
  .chart-container-width {
    width: 110%;
  }
}
@media (max-width: 992px) {
  .chart-container-width {
    width: 113%;
  }
}
@media (max-width: 768px) {
  .chart-container-width {
    width: 118%;
  }
}
@media (max-width: 576px) {
  .chart-container-width {
    width: 138%;
  }
}

.chart-container {
  position: relative;

  height: 400px;
  /* padding: 1rem; */
  flex: 2 2 0;
}
.current-price-label-wrapper {
  position: absolute;
  margin: 0;
  padding: 0;
  right: 120px;
  /* z-index: 99999; */
  transition: all 0.3s;
  background-color: rgb(46, 74, 159);
  height: 25px;
}
.current-price-triangle {
  position: absolute;
  top: -0.6rem;
  left: -17.5px;
  height: 0;
  width: 0;
  border-top: 17.5px solid transparent;
  border-bottom: 17.5px solid transparent;
  border-right: 17.5px solid rgb(46, 74, 159);
  /* z-index: 99999999; */
}
.current-price-label {
  position: relative;
  margin: 0;
  padding: 0;
  top: -0.6rem;
  font-size: 0.8rem;
  padding: 0.5rem;
  color: #fff;
  background-color: rgb(46, 74, 159);
}
.chart {
  height: 250px;
}
.progressBarPadding {
  padding: 30px 0px;
}
</style>