<template>
  <div class="game-container">
    <Navigation />
    <div id="block_container">
      <SideNav :propObj="sideNavProps" />
      <div id="main-page">
        <div>
          <div class="row">
            <div class="chart-container">
              <canvas class="chart-canvas" ref="chart"></canvas>
              <div :style="`top: calc(${chartTop}px + ${verticalPos + 8}px);`" style="position: absolute; width: 89%; border-top: 1px dotted rgb(60, 125, 231);"></div>
              <div
                class="current-price-label-wrapper"
                :style="`top: calc(${chartTop}px + ${verticalPos}px);`"
              >
                <div class="current-price-triangle"></div>
                <p class="current-price-label">${{ lastPrice.toFixed(2) }}</p>
              </div>
            </div>
          </div>
          <div class="row betting-block">
            <div class="col-lg-3">
              <div class="col-xs-6">
                <label class="label">Amount(BTC)</label><br />
                <input
                  type="text"
                  class="form-control stake-input"
                  v-model="stake"
                  :disabled="tradeIsOpen"
                />
                <!-- <input
                  class="stake-input"
                  type="number"
                  v-model="stake"
                  :disabled="tradeIsOpen"
                /> -->
                <!-- <label class="label-value">$ 100</label> -->
              </div>
              <div class="col-xs-6">
                <img
                  src="assets/Plus.png"
                  width="30"
                  height="30"
                  @click="incrementStake"
                /><br />
                <img
                  src="assets/Minus.png"
                  width="30"
                  height="30"
                  @click="decrementStake"
                />
              </div>
            </div>
            <div class="col-lg-2">
              <div class="dropdown-item">
                <label>{{ __("Duration") }}</label>
                <vue-select
                  v-model="expiry"
                  :options="expirations"
                  :clearable="false"
                  :value="expiry"
                  :disabled="tradeIsOpen"
                ></vue-select>
              </div>
            </div>
            <div class="col-lg-5">
              <div v-show="message" class="message">
                {{ message }}
              </div>
              <div v-show="secondsToExpiry > 0" class="progress-bar">
                <div
                  :style="{ width: (secondsToExpiry / duration) * 100 + '%' }"
                ></div>
              </div>
              <p v-if="error" class="error-message">{{ error }}</p>
            </div>
            <div class="col-lg-2">
              <img
                src="assets/Up Arrow.png"
                width="40"
                height="30"
                @click="tradeIsOpen || !canTrade ? null : trade(1)"
              /><br />
              <img
                src="assets/Low Arrow.png"
                width="40"
                height="30"
                @click="tradeIsOpen || !canTrade ? null : trade(-1)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import Navigation from "./Navigation.vue";
import SideNav from "./SideNav.vue";
// import styles from '../../../scss/style.scss'
import numeral from "numeral";
import assets from "../static/assets.json";
import * as nf from "../modules/numberFormat";
import * as cf from "../modules/colorFormat";
import Chart from "chart.js";
import RestApi from "../modules/restApi";
import WsApi from "../modules/websocketApi";
import "vue-select/dist/vue-select.css";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import store from "../store";

numeral.register("locale", "custom", {
  delimiters: {
    decimal: ".",
    thousands: ",",
  },
  ordinal: function (number) {
    return "";
  },
  currency: {
    symbol: "$",
  },
});
numeral.locale("custom");

export default {
  name: "Home",
  components: {
    Navigation,
    SideNav,
  },
  mixins: [],
  data() {
    return {
      sideNavProps: { selected: "home" },
      config: {
        id: "cryptocurrency-price-prediction-game",
        code: "cppg",
        historyLength: 20,
        thousandsSeparator: ",",
        decimalSeparator: ".",
        locale: "en-US",
        text: {},
      },

      userID: "",
      balances: null,
      history: {
        gamesPlayed: 0,
      },
      error: null,
      currentGameID: null,

      color: "rgba(255,203,48,1)",
      asset: null,
      expiry: null,
      secondsToExpiry: 0,
      stake: 0,
      return: this.$attrs.return ? parseFloat(this.$attrs.return) : 0.8,
      quotes: [],
      chart: null,
      restApi: null,
      wsApi: null,
      message: null,
      defaultUserData: {
        balance: 1000,
        wins: 0,
        losses: 0,
        trade: null,
      },
      userData: null,
      timeout: null,
      interval: null,
      currentPricePosY: 0,
      chartTop: null,
      chartBottom: null,
    };
  },
  computed: {
    // getting the user data after login
    // getting 2 key "authenticated(boolean)" and "user(object)"
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),

    assets() {
      return assets;
    },
    expirations() {
      return [
        { value: 5, label: this.__("5 seconds") },
        { value: 15, label: this.__("15 seconds") },
        { value: 30, label: this.__("30 seconds") },
        { value: 45, label: this.__("45 seconds") },
        { value: 60, label: this.__("1 minute") },
        { value: 120, label: this.__("2 minutes") },
        { value: 300, label: this.__("5 minutes") },
        { value: 600, label: this.__("10 minutes") },
        { value: 900, label: this.__("15 minutes") },
        { value: 1800, label: this.__("30 minutes") },
        { value: 3600, label: this.__("1 hour") },
      ];
    },
    dates() {
      return this.quotes.map((quote) => quote.date);
    },
    prices() {
      return this.quotes.map((quote) => quote.price);
    },
    balance() {
      if (!this.balances) return 0;
      let asset = this.balances.find(
        (coin) => coin.currencySymbol === this.asset.symbol
      );
      if (asset) return asset.gameBalance;
      else return 0;
    },
    _balance() {
      return nf.decimal(this.balance) + " " + this.asset.symbol;
    },
    _payout() {
      return this.stake > 0
        ? nf.decimal(this.stake * (1 + this.return)) +
            " " +
            this.asset.symbol +
            " (" +
            nf.percentage(this.return * 100) +
            ")"
        : "";
    },
    lastPrice() {
      var n = this.quotes.length;
      return n > 0 ? this.quotes[this.quotes.length - 1].price : 0;
    },
    _lastPrice() {
      return nf.variableDecimal(this.lastPrice);
    },
    duration() {
      return this.userData.trade
        ? this.userData.trade.expiry - this.userData.trade.date
        : 0;
    },
    _lastPriceIntegerPart() {
      return nf.integer(Math.floor(this.lastPrice));
    },
    _lastPriceDecimalPart() {
      var string = this.lastPrice + "";
      var n = string.indexOf(".");
      return n > -1 ? string.substring(n + 1, n + 9) : "";
    },
    tradeIsOpen() {
      return this.userData.trade !== null;
    },
    canTrade() {
      return (
        !this.tradeIsOpen &&
        this.stake >= 0 &&
        this.userData.balance >= this.stake &&
        this.lastPrice > 0
      );
    },
    verticalPos() {
      return this.currentPricePosY;
    },
  },
  methods: {
    // display chart
    displayChart() {
      // if the chart is already initialized update the chart data
      if (this.chart !== null) {
        this.chart.config.data.labels = this.dates;
        this.chart.config.data.datasets[0].data = this.prices;
        this.chart.update();
        this.updateUI();
        return;
      }

      var chartData = {
        labels: this.dates,
        datasets: [
          {
            data: this.prices,
            borderColor: this.color,
            borderWidth: 1,
            backgroundColor: cf.gradient(
              this.$refs.chart,
              "top to bottom",
              300,
              [
                cf.shadeBlend(0.1, this.color, "rgba(255,203,48,0)"),
                cf.shadeBlend(0.4, this.color, "rgba(255,203,48,0)"),
                cf.shadeBlend(0.7, this.color, "rgba(255,203,48,0)"),
                cf.shadeBlend(0.9, this.color, "rgba(255,203,48,0)"),
              ]
            ),
            radius: 0,
            hoverRadius: 5,
            hitRadius: 0,
            pointRadius: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4,
            ],
            keepTooltipOpen: true,
          },
        ],
      };

      var chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            right: 120,
          },
        },
        hover: {
          mode: "nearest",
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false, // display tooltip at all times
          cornerRadius: 6,
          titleFontColor: "#fff",
          bodyFontSize: 14,
          displayColors: false,
          bodyFontColor: "#fff",
          backgroundColor: "#84929c",
          borderColor: this.color,
          borderWidth: 0,
          xPadding: 16,
          yPadding: 16,
          bodySpacing: 5,
          callbacks: {
            label: (tooltipItem, data) => {
              return (
                this.asset.name + " $" + nf.variableDecimal(tooltipItem.yLabel)
              );
            },
          },
        },
        elements: {
          line: {
            tension: 0.4,
          },
          point: {
            radius: 3,
          },
        },
        legend: {
          display: false,
        },
        animation: {
          duration: 300,
          easing: "easeInOutSine",
          onComplete: () => {
            // this.updateUI();
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              ticks: {
                maxRotation: 0,
                padding: 5,
                fontColor: "rgba(38,124,237,1)",
                callback: (value, index, values) => {
                  if ((index + 1) % 5 === 0) {
                    return new Date(value).toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    });
                  } else return;
                },
              },
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                fontColor: "rgba(38,124,237,1)",
                callback: (value, index, values) =>
                  "$" + nf.variableDecimal(value),
              },
              gridLines: {
                display: true,
                drawBorder: false,
                color: "rgba(38,124,237,.2)",
              },
            },
          ],
        },
      };

      this.chart = new Chart(this.$refs.chart, {
        type: "line",
        data: chartData,
        options: chartOptions,
      });
    },
    // update chart and live data when asset is changed
    updateAsset() {
      this.quotes = [];
      this.restApi.getHistory(
        this.asset.id,
        "m1",
        this.config.historyLength,
        this.config.historyLength
      );
      this.wsApi.subscribe(this.asset.id);
    },
    // make a new trade
    trade(direction) {
      var now = Math.round(new Date().getTime() / 1000);
      var expiry = parseInt(this.expiry.value);

      this.userData.balance -= this.stake; // decrease user balance

      this.userData.trade = {
        date: now,
        expiry: now + expiry,
        direction: direction,
        stake: this.stake,
        price: this.lastPrice,
      };

      // console.log(this.userData.trade);
      // console.log("token ", localStorage.getItem("token"));

      axios({
        method: "post",
        url: "api/game/gameStake",
        data: {
          userId: this.userID,
          amount: this.stake,
          direction: direction,
          asset: this.asset.symbol,
          trade_price: this.lastPrice,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        // console.log("api/game/gameStake",response);
        if (!response.data.status) {
          this.error = response.data.message;
          this.userData.trade = null;
          this.saveUserData();
        } else {
          this.error = null;
          this.currentGameID = response.data.data._id;
          this.secondsToExpiry = this.expiry.value;
          this.setCheckTradeExpiryInterval();
          this.saveUserData();
        }
      });

      clearTimeout(this.timeout); // if trade is started before win / lose message disappears
    },
    // set interval for checking trade expiry
    setCheckTradeExpiryInterval() {
      this.message = this.__("Open price") + ": $" + this.userData.trade.price;
      // check trade for expiration every second
      this.interval = setInterval(() => this.checkTradeExpiry(), 200);
    },
    // check if trade is expired
    checkTradeExpiry() {
      var trade = this.userData.trade;
      this.secondsToExpiry = trade.expiry - Date.now() / 1000;

      // trade is expired
      if (this.secondsToExpiry <= 0) {
        clearInterval(this.interval);

        var priceDifference = (this.lastPrice - trade.price) * trade.direction;

        if (priceDifference > 0) {
          var payout = trade.stake * (1 + this.return);
          this.message = this.__("You win") + " $" + payout;
          this.userData.balance += payout;
          this.userData.wins++;
        } else {
          this.message = this.__("You lose");
          this.userData.losses++;
        }

        // console.log(this.currentGameID);

        let direction = priceDifference > 0 ? 1 : -1;
        // if (priceDifference === 0) direction = 0;

        axios({
          method: "post",
          url: "api/game/winOrLose",
          data: {
            userId: this.userID,
            asset: this.asset.symbol,
            direction: direction,
            gameId: this.currentGameID,
            last_price: this.lastPrice,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }).then((response) => {
          // console.log(response);
          if (!response.data.status) {
            this.error = response.data.message;
          }
          this.fetchBalance();
        });

        // clear the message in 5 seconds
        this.timeout = setTimeout(() => {
          this.message = null;
        }, 5000);

        this.userData.trade = null;

        this.saveUserData();
      }
    },
    // save user data to browser localStorage
    saveUserData() {
      localStorage.setItem(this.config.code, JSON.stringify(this.userData));
    },
    // translate string
    __(string) {
      return this.config.text[string] || string;
    },
    updateUI() {
      let data = this.chart.getDatasetMeta(0).data;
      let lastPointPosition = data[data.length - 1];
      if (!lastPointPosition) return;
      this.currentPricePosY = lastPointPosition._model.y;

      let chartScale = this.chart.getDatasetMeta(0).dataset._scale;
      if (!chartScale) return;
      this.chartTop = chartScale.top;
      this.chartBottom = chartScale.bottom;
    },
    incrementStake() {
      this.stake++;
    },
    decrementStake() {
      this.stake--;
    },

    checkCoinImage(symbol) {
      try {
        let imagePath = require(`@/assets/coins/${symbol}.png`);
        if (imagePath) return true;
      } catch (error) {
        return false;
      }
    },

    fetchBalance() {
      axios({
        method: "post",
        url: "api/address/balance",
        data: { wallet_type: "BTC" },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.balances = response.data.data;
        // console.log("get balance:", this.balances);
      });
    },
  },
  created() {
    this.userID = localStorage.getItem("userId");
    Chart.plugins.register({
      afterDraw: (chart) => {
        // console.log(chart.config.data.datasets);
        if (chart && chart.config.data.datasets[0].data.length == 0) {
          // No data is present
          var ctx = chart.chart.ctx;
          var width = chart.chart.width;
          var height = chart.chart.height;
          chart.clear();

          ctx.save();
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "20px Arial";
          ctx.fillStyle = this.color;
          ctx.fillText(
            this.__("No historical data is available for this asset"),
            width / 2,
            height / 2
          );
          ctx.restore();
        }
      },
    });

    // init asset and expiry dropdowns
    this.asset = this.assets[parseInt(this.$attrs.asset) || 166]; // this will trigger updateAsset() function
    this.expiry = this.expirations[1];

    var userData = localStorage.getItem(this.config.code);
    this.userData = userData ? JSON.parse(userData) : this.defaultUserData;

    this.$on("quote", (quote) => {
      if (this.chart && this.quotes.length) {
        this.quotes.push({ date: new Date().getTime(), price: quote });

        if (this.quotes.length > this.config.historyLength) this.quotes.shift();

        this.displayChart();
      }
    });

    this.restApi = new RestApi({
      onHistory: (history) => {
        // console.log("history ", history);
        this.quotes = history;
        this.displayChart();
      },
    });

    this.restApi.getHistory(
      this.asset.id,
      "m1",
      this.config.historyLength,
      this.config.historyLength
    );

    this.wsApi = new WsApi({
      onMessage: (quote) => this.$emit("quote", quote),
    });

    this.updateAsset();
  },
  mounted() {
    if (this.userData.trade) {
      this.setCheckTradeExpiryInterval();
      this.checkTradeExpiry();
    }

    if (this.userID) {
      axios({
        method: "post",
        url: "api/game/showGameStats",
        data: {},
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        // console.log("game stats:", response);
      });
      this.fetchBalance();
    }
  },
};
</script>


<style scoped>
 /* @import url("https://use.typekit.net/fmt7ldx.css"); */
:root {
  --paletteColor1: #3c7de7;
  --paletteBG: rgba(188, 197, 212, 0.1);
  --paletteButton: #547ff0;
  --paletteButtonHover: #e4e4e4;
}

* {
  font-family: "Eurostile";
}

.game-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  height: 100vh;
}
#block_container {
  display: flex;
  text-align: center;
}
#main-page {
  width: 90%;
  height: 100vh;
  display: inline;
  background-color: #152667;
  color: white;
  padding: 16px;
}
.chart-container {
  position: relative;
  height: 420px;
  padding: 1rem;
  flex: 2 2 0;
}
.current-price-label-wrapper {
  position: absolute;
  margin: 0;
  padding: 0;
  right: 0;
  z-index: 99999;
  transition: all 0.3s;
}
.current-price-triangle {
  position: absolute;
  top: -1.5rem;
  left: -17.5px;
  height: 0;
  width: 0;
  border-top: 17.5px solid transparent;
  border-bottom: 17.5px solid transparent;
  border-right: 17.5px solid var(--paletteColor1);
  z-index: 99999999;
}
.current-price-label {
  position: relative;
  margin: 0;
  padding: 0;
  top: -1.15rem;
  font-size: 1.3rem;
  padding: 0.5rem;
  color: #fff;
  background-color: var(--paletteColor1);
}
.betting-block {
  margin-top: 50px;
}
.stake-input {
  padding: 0.5rem 0.5rem;
  /* width: 100px; */
  margin-top: 6px;
  background: transparent;
  border: 0px;
  color: white;
  font-size: 26px;
}
.dropdowns {
  display: flex;
  flex-direction: row;
}

.dropdown-item {
  border-radius: 0.5rem;
  color: #fff;
}

.dropdown-item label {
  font-size: 16px;
  text-transform: uppercase;
}

.dropdown-item span {
  color: #fff;
}

.vs__dropdown-toggle {
  border: 1px solid #fff;
}

.vs__actions svg {
  fill: #fff;
}

.label {
  font-size: 16px;
  margin-top: 14px;
}
.label-value {
  font-size: 22px;
  margin-top: 11px;
}
.message {
  font-size: 18px;
  color: white;
}
.error-message {
  color: #c62626;
}
.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #f9c633;
}
.progress-bar > div {
  height: 10px;
  background-color: #a5a9b1;
}
</style>