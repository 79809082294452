
import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '../components/Login.vue';
// import Register from '../components/Register.vue';
// import Verification from '../components/Verification';
// import ForgotPassword from '../components/ForgotPassword';
// import ResetPassword from '../components/ResetPassword';

import Home from '../components/Home.vue';
import Game from '../components/Game.vue';
// import DepositWithdrawal from '../components/DepositWithdrawal.vue';
import BetHistory from '../components/BetHistory.vue';
// import SettingSecurity from '../components/SettingSecurity.vue';
// import SettingProfile from '../components/SettingProfile.vue';
// import SettingChangePassword from '../components/SettingChangePassword.vue';
import PageNotFound from '../components/PageNotFound.vue';
// import Test from '../components/Test.vue';

import store from './../store';
import constants from "./../modules/constants";
import axios from "axios";
var CryptoJS = require("crypto-js");

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: PageNotFound
  },
  {
    path: '/:lang',
    component: {
      render(c) {return c('router-view')}
    },
    children: [
      {
        path: '/',
        component: PageNotFound
      },
      // {
      //   path: '/login',
      //   name: 'login',
      //   component: Login
      // },
      // {
      //   path: '/verification/:code',
      //   name: 'verification',
      //   component: Verification,
      //   props: true
      // },
      // {
      //   path: '/forgotPassword/',
      //   name: 'forgotPassword',
      //   component: ForgotPassword
      // },
      // {
      //   path: '/resetPassword/:code',
      //   name: 'resetPassword',
      //   component: ResetPassword,
      //   props: true
      // },
      // {
      //   path: '/register',
      //   name: 'register',
      //   component: Register
      // },
      {
        path: 'game/:token',
        name: 'game',
        component: Game,
        beforeEnter: (to, from, next) => {
          if (!to.hasOwnProperty('params') || !to.params.hasOwnProperty('token')) {
            return next({
              name: 'pageNotFound'
            })
          }

          var encryptedToken = to.params.token;
          var re = new RegExp('_', 'g');
          var todecrypt = encryptedToken.toString().replace(re, '/')
          let decryptToken = CryptoJS.AES.decrypt(todecrypt, constants.CRYPTOJSKEY).toString(CryptoJS.enc.Utf8)

          axios({
            method: "get",
            url: "api/users/me",
            headers: {
              Authorization: "Bearer " + decryptToken,
            },
          }).then((response) => {
            if (response.data.status != "success") {
              return next({
                name: 'pageNotFound'
              })
            } else {
              localStorage.setItem('token', decryptToken);
              localStorage.setItem('userId', response.data.data._id);
            }
          }).catch((err) => {
            console.log("err", err);
            return next({
              name: 'pageNotFound'
            })
          });

          next()
        }
      },
      // {
      //   path: 'depositWithdrawal',
      //   name: 'depositWithdrawal',
      //   component: DepositWithdrawal,
      //   beforeEnter: (to, from, next) => {
      //     if (!store.getters['auth/authenticated']) {
      //       return next({
      //         name: 'login'
      //       })
      //     }
      //     next()
      //   }
      // },
      {
        path: 'betHistory',
        name: 'betHistory',
        component: BetHistory,
        // beforeEnter: (to, from, next) => {
        //   if (!store.getters['auth/authenticated']) {
        //     return next({
        //       name: 'pageNotFound'
        //     })
        //   }
        //   next()
        // }
      },
      // {
      //   path: 'setting/security',
      //   name: 'settingSecurity',
      //   component: SettingSecurity,
      //   beforeEnter: (to, from, next) => {
      //     if (!store.getters['auth/authenticated']) {
      //       return next({
      //         name: 'login'
      //       })
      //     }
      //     next()
      //   }
      // },
      // {
      //   path: 'setting/profile',
      //   name: 'settingProfile',
      //   component: SettingProfile,
      //   beforeEnter: (to, from, next) => {
      //     if (!store.getters['auth/authenticated']) {
      //       return next({
      //         name: 'login'
      //       })
      //     }
      //     next()
      //   }
      // },
      // {
      //   path: 'setting/changePassword',
      //   name: 'settingChangePassword',
      //   component: SettingChangePassword,
      //   beforeEnter: (to, from, next) => {
      //     if (!store.getters['auth/authenticated']) {
      //       return next({
      //         name: 'login'
      //       })
      //     }
      //     next()
      //   }
      // },
      {
        path: '*',
        name: 'pageNotFound',
        component: PageNotFound,
      },
      // {
      //   path: 'test',
      //   name: 'test',
      //   component: Test,
      // },
    ]
  }
]

const router = new VueRouter({
  base: "/",
  mode: 'history',
  routes
})

export default router
