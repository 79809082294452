
const environment = "PRODUCTION"; // TESTING, PRODUCTION, DEVELOPMENT
/**
 * Production environmet URL
 */
const PROD_BASE_URL = 'https://bitbinaryoption.com:9025/'; 
const PROD_PHP_BASE_URL = "https://bitbinaryoption.com/";

/**
 * Test environment base url
 */
const TEST_BASE_URL = 'http://128.199.129.139:9025/';
const TEST_PHP_BASE_URL = "https://ceewen.xyz/binaryoptions-frontend/";

/**
 * Local base url
 */
const DEV_BASE_URL = 'http://24f8-2406-b400-d1-57c4-1633-a0a4-1409-2421.ngrok.io/';
const DEV_PHP_BASE_URL = "https://ceewen.xyz/binaryoptions-frontend/";


let APIBASEURL = (environment == "PRODUCTION") ? PROD_BASE_URL : (environment == "TESTING") ? TEST_BASE_URL : DEV_BASE_URL;
let PHPBASEURL = (environment == "PRODUCTION") ? PROD_PHP_BASE_URL : (environment == "TESTING") ? TEST_PHP_BASE_URL : DEV_PHP_BASE_URL;

module.exports = APIURLS = {
    APIBASEURL: APIBASEURL,
    PHPBASEURL: PHPBASEURL,
    CRYPTOJSKEY: "a93d02d0131asdabc",
}