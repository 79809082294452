import Vue from 'vue'
import router from './router/router'
import VueSelect from 'vue-select/dist/vue-select'
import App from './App.vue';
import store from './store'
import axios from 'axios';
import constants from './modules/constants';
import i18n from './i18n'


import VueToastr from "vue-toastr";

Vue.use(VueToastr, {
  /* OverWrite Plugin Options if you need */
  defaultPosition: "toast-bottom-right",
});

// // import polyfills for older browsers
// if (!global._babelPolyfill)
//     require('@babel/polyfill');

require('./store/subscriber');

axios.defaults.baseURL = constants.APIBASEURL; //test server
// axios.defaults.baseURL = "http://06036c5ecaf6.ngrok.io/"; // local

// console.log("process.env.NODE_ENV ", process.env.NODE_ENV);
// Vue game component
Vue.component('vue-select', VueSelect);

// Vue.use(VueToast);
// Vue.$toast.open('You did it!');




router.beforeEach((to, from, next) => {

  let language = to.params.lang;
  if(!language){
    language = 'en'
  }
  
  i18n.locale = language
  next()
})




store.dispatch('auth/attempt', localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app');
})
