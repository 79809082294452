<template>
  <div>
    <div class="footer">
      <p class="mb-0">
        Copyright © 2021 Bitbinary Options |
        <a
          class="text-bold-800 grey darken-2"
          data-toggle="modal"
          data-target="#privacyModel"
        >
          Privacy Policy
        </a>
      </p>
    </div>
    <div
      class="modal fade"
      id="privacyModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Privacy Policy
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              We treat your privacy very seriously. We realise you may wish to
              know how we will use the information we collect from or about you.
              This Privacy Policy provides an explanation as to what happens to
              any personal data that you provide to us.
            </p>
            <br />
            <p>
              By using our Site, you agree with the collection, the use and the
              disclosure of your personal data in accordance with this Privacy
              Policy. We may change the content or services found on the Site at
              any time without notice, and consequently our Privacy Policy may
              change at any time in the future. Your continued access to or use
              of the Site will mean that you agree to the policy that applies
              when you use the site.
            </p>
            <br />
            <p><b>Information we collect about you</b></p>
            <br />
            <p>
              By “your information” we mean personal and financial information
              we obtain from you. This covers information that you provide by
              filling in forms on our website, or when you register and
              subscribe for information from us. Including any information
              provided to us when you communicate with us for any reason, or
              during a financial review or provided by third parties and other
              organisations when you request our investment advisory services.
            </p>
            <br />
            <p>
              We will store and process your information on our data systems and
              in any other medium. We will use your information to provide our
              services and ongoing advice and support to you. We will retain
              information about you as long as you are a client of Bitbinary
              Options. If for any reason you are no longer a client we will
              delete and no longer hold any of your personal private and
              confidential information on file.
            </p>
            <br />
            <p><b>Confidentiality within Bitbinary Options</b></p>
            <br />
            <p>
              Only designated and authorised personal working at Bitbinary
              Options will have access to your personal data and information.
              This would generally include your personal financial advisor and
              the client servicing team member processing and handling any
              administration on your behalf.
            </p>
            <br />
            <p><b>Only relevant third party disclosure</b></p>
            <br />
            <p>
              We will not share your personal or confidential information with
              anyone or any companies outside of Bitbinary Options that are not
              relevant third parties. Relevant third parties are people who
              provide a service to us or are acting as our agents, on the
              understanding that they will keep the information confidential and
              allow us to provide the requested advice and servicing to you.
            </p>
            <br />
            <p>
              If we disclose your information to a service provider (a person,
              office or organisation) located in another country, we will take
              steps reasonably necessary to ensure that they apply the same
              levels of protection as we are required to apply to your
              information and to use your information only for the purpose of
              providing the service to us. By submitting your personal
              information you agree to this transfer.
            </p>
            <br />
            <p><b>Storing your personal data</b></p>
            <br />
            <p>
              We may transfer data that we collect from you internationally for
              processing and storing. Also, it may be processed by staff
              operating internationally and who work with us as a relevant third
              party or servicing provider. By submitting your personal data, you
              agree to this transfer, storing or processing. We will take all
              reasonable steps to make sure that your data is treated securely
              and in agreement with this Privacy Policy.
            </p>
            <br />
            <p>
              Data that is provided to us is stored on our secure servers. The
              transmission of information via the internet is not completely
              secure and therefore we cannot guarantee the security of data sent
              to us electronically and transmission of such data is therefore
              entirely at your own risk. Where we have given you (or where you
              have chosen) a password so that you can access certain parts of
              our site, you are responsible for keeping this password
              confidential.
            </p>
            <br />
            <p><b>Use of Cookies</b></p>
            <br />
            <p>
              A ‘cookie’ is a small piece of information that we transfer to
              your computer’s hard drive through your Web browser to allow us to
              recognise your browser. This is useful for having the browser
              remember some specific information and to provide features such as
              providing you with information you have confirmed you are able to
              access, between visits.
            </p>
            <br />
            <p>
              They are used for ‘website tracking’ and ‘targeted marketing’.
              Website tracking allows us to obtain accurate counts of how many
              people have been to pages on our site and assists us to build up a
              profile of where our visitors go and what their preferences are.
              ‘Targeted marketing’ allow us to target coverage to various
              visitor groups and provide them with information which we believe
              would have interest.
            </p>
            <br />
            <p>
              ‘Cookies’ allow you to take full advantage of our site and are
              essential for its operation and we recommend therefore that you
              leave them turned on. Should you choose not to accept ‘cookies’
              from our Site you will be unable to access it.
            </p>
            <br />
            <p><b>External Links</b></p>
            <br />
            <p>
              All of the external sites you can reach through Bitbinary Options
              Management are here to help you. However, we must tell you that we
              accept no responsibility or liability for the content, accuracy or
              availability of any external site you may encounter.
            </p>
            <br />
            <p class="float-right">
              Bitbinary Options TECHNOLOGY &amp; MANAGEMENT LIMITED
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
</style>