<template>
  <div id="sidenav">
    
    <!-- <div class="nav-container">
      <div class="mobile-topbar-header">
        <div class="">
          <img
            src="assets/logo_m.png"
            class="logo-icon-2"
            alt="Bitbinary Options logo"
          />
        </div>
        <div>
          <h4 class="logo-text">
            <img
            src="assets/logo_txt.png"
            class=""
            alt="Bitbinary Options logo"/>
          </h4>
        </div> -->
        <!-- <a href="javascript:;" class="toggle-btn ml-auto">
          <i class="bx bx-menu"></i>
        </a> -->
      <!-- </div> -->
      <!-- <nav class="topbar-nav">
        <ul class="metismenu" id="menu">
          <li>
            <a href="javascript:;" class="has-arrow">
              <div class="parent-icon icon-color-1">
                <i class="bx bx-home-alt"></i>
              </div>
              <div class="menu-title">Game</div>
            </a>
            <ul>
              <li>
                <a href="index.html"
                  ><i class="bx bx-right-arrow-alt"></i>Analytics</a
                >
              </li>
            
            </ul>
          </li>
          <li>
            <a href="javascript:;" class="has-arrow">
              <div class="parent-icon icon-color-2">
                <i class="bx bx-briefcase-alt"></i>
              </div>
              <div class="menu-title">History</div>
            </a>
            <ul>
              <li>
                <a href="emailbox.html"
                  ><i class="bx bx-right-arrow-alt"></i>Email</a
                >
              </li>
              
              <li>
                <a href="contact-list.html"
                  ><i class="bx bx-right-arrow-alt"></i>Contacts</a
                >
              </li>
              <li>
                <a href="to-do.html"
                  ><i class="bx bx-right-arrow-alt"></i>Todo List</a
                >
              </li>
              
            </ul>
          </li>
         
        </ul>
      </nav> -->
    <!-- </div> -->
     <div class="card">
          <div class="card-body">
            <!-- <div class="d-flex mb-2"> -->
            <!-- <apexchart
              ref="apexchart"
              type="area"
              :options="chartOptions"
              :series="series"
              width="100%"
              height="400"
            >
            </apexchart> -->
            <!-- </div> -->
            <div class="page-breadcrumb d-md-flex align-items-center">
              <div
                class="btn-group m-1 ml-2"
                role="group"
                aria-label="Basic example"
              >
                <a :href="homeLink" type="button" class="btn btn-primary">
                  <i class="bx bx-home-alt"></i>
                </a>
                <a :href="betHistoryLink" type="button" class="btn btn-primary">
                  <i class="bx bx-dna"></i>
                </a>
              </div>
              <!-- <div class="breadcrumb-title pr-3">
                <a href="javascript:;"><i class='bx bx-home-alt cm_icons'></i></a>
              </div>
              <div class="breadcrumb-title pr-3">
                <a href="javascript:;"><i class='bx bx-home-alt cm_icons'></i></a>
              </div> -->
              <div class="ml-auto">
                <div class="btn-group">
                  <!-- <button type="button" class="btn btn-primary m-1 px-5"><i class="bx bx-wallet-alt"></i> Deposites</button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import store from "./../store";
import constants from "./../modules/constants";
var CryptoJS = require("crypto-js");

export default {
  name: "SideNav",
  props: ["propObj"],
  data() {
    return {
      store: store,
      homeLink: this.$i18n.locale+"/game/" + this.encryptToken(),
      betHistoryLink: this.$i18n.locale+"/betHistory/",
      lang: this.$i18n.locale,
    };
  },
  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
    }),

    encryptToken() {
      var re = new RegExp("/", "g");
      return CryptoJS.AES.encrypt(
        localStorage.getItem("token"),
        constants.CRYPTOJSKEY
      )
        .toString()
        .replace(re, "_");
    },

    signOut() {
      this.signOutAction().then(() => {
        this.$router.replace({
          name: "login",
        });
      });
    },
  },
};
</script>

<style scoped>
/* #sidenav {
  width: 10%;
  display: inline;
  background-color: #152667;
}

#sidenav ul li {
  padding: 20px;
}
#sidenav ul li a {
  color: white;
  text-decoration: none;
  align-items: center;
} */
</style>