<template>
    <div class="main-div"> Page Not Found</div>
</template>
<script >
export default {
    name: "PageNotFound",
}
</script>

<style scoped>
.main-div {
    display: flex;
    color: white;
    background-color: #192e7e;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    height: 100vh;
}
</style>