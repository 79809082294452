<template>
  <!-- <nav v-if="!['verification', 'forgotPassword'].includes($router.history.current.name)"> -->

  <!--header-->
  <header class="top-header">
    <nav class="navbar navbar-expand">
      <div class="sidebar-header">
        <a :href="phpbaseurl"><img src="assets/Binary_Options_blue.png" height="40" /></a>
        <!-- <div class="d-none d-lg-flex">
						<img src="assets/images/logo-icon.png" class="logo-icon-2" alt="" />
					</div>
					<div>
						<h4 class="d-none d-lg-flex logo-text">Syndash</h4>
					</div> -->
        <!-- <a href="javascript:;" class="toggle-btn ml-lg-auto">
          <i class="bx bx-menu" style="color: #404142"></i>
        </a> -->
      </div>
      
      <div class="right-topbar ml-auto">
                      <a :href="phpbaseurl+`dashboard`" class="btn btn-primary m-1 px-5"><i class="bx bx-wallet-alt"></i> {{$t('Deposites')}}</a>
        <!-- <ul class="navbar-nav">
          
          <li class="nav-item dropdown dropdown-user-profile">
            <a
              class="nav-link dropdown-toggle dropdown-toggle-nocaret"
              href="javascript:;"
              data-toggle="dropdown">
              <div class="media user-box align-items-center">
                <div class="media-body user-info">
                  <p class="user-name mb-0">
                  </p>
                </div>
                <img
                  src="https://via.placeholder.com/110x110"
                  class="user-img"
                  alt="user avatar"/>
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" href="javascript:;"
                ><i class="bx bx-user"></i><span>Profile</span></a
              >
              <a class="dropdown-item" href="javascript:;"
                ><i class="bx bx-cog"></i><span>Settings</span></a
              >
              <a class="dropdown-item" href="javascript:;"
                ><i class="bx bx-tachometer"></i><span>Dashboard</span></a
              >
              <a class="dropdown-item" href="javascript:;"
                ><i class="bx bx-wallet"></i><span>Earnings</span></a
              >
              <a class="dropdown-item" href="javascript:;"
                ><i class="bx bx-cloud-download"></i><span>Downloads</span></a
              >
              <div class="dropdown-divider mb-0"></div>
              <a class="dropdown-item" href="javascript:;"
                ><i class="bx bx-power-off"></i><span>Logout</span></a
              >
            </div>
          </li>
          
        </ul> -->
      </div>
    </nav>
  </header>
  <!--end header-->

  <!-- <nav>
    <router-link :to="homeLink"
      ><img src="assets/Binary_Options_white.png" height="65"
    /></router-link> -->
  <!-- <router-link to="/login">Login</router-link>
    <router-link to="/register">Register</router-link> -->
  <!-- <a v-if="store.getters['auth/authenticated']" @click.prevent="signOut">Logout</a> -->
  <!-- <div style="border: 1px solid white; border-radius: 2rem; padding: 9px;"> -->
  <!-- <a :href="phpbaseurl + 'dashboard'">
      <img src="assets/Deposites.png" height="70" />
    </a> -->
  <!-- </div> -->
  <!-- </nav> -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import store from "./../store";
import constants from "./../modules/constants";
var CryptoJS = require("crypto-js");

export default {
  name: "Navigation",
  components: {},
  data() {
    return {
      homeLink: "/game/" + this.encryptToken(),
      store: store,
      phpbaseurl: constants.PHPBASEURL,
    };
  },
  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
    }),

    encryptToken() {
      var re = new RegExp("/", "g");
      return CryptoJS.AES.encrypt(
        localStorage.getItem("token"),
        constants.CRYPTOJSKEY
      )
        .toString()
        .replace(re, "_");
    },

    signOut() {
      this.signOutAction().then(() => {
        this.$router.replace({
          name: "login",
        });
      });
    },
  },
};
</script>

<style scoped>
/* nav {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #152667;
}

nav a {
  padding: 0.2rem 1.5rem;
  color: #fff;
  text-decoration: none;
} */
nav a:hover {
  /* background-color: rgba(255, 255, 255, 0.1); */
}
</style>
