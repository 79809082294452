<template>
  <!-- <div id="app"> -->
    <router-view />
  <!-- </div> -->
</template>

<script>
// import Navigation from "./components/Navigation.vue";

export default {
  name: "App",
};
</script>

<style>

</style>
