import axios from "axios";

export default {
    namespaced: true,
    state: {
        token: null,
        user: null

    },
    getters: {
        authenticated(state) {
            return state.token && state.user;
        },
        user(state) {
            return state.user;
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_USER(state, user) {
            state.user = user;
        }
    },
    actions: {
        async signIn({ dispatch }, token) {

            // console.log("credentials ", token);
            // get the token from api, pass the token in dispatch
            return await dispatch('attempt', token);
        },

        async attempt({ commit, state }, token) {

            if (token) {
                // console.log("token", token);
                commit('SET_TOKEN', token);
            }
            if (!state.token) {
                return;
            }

            try {
                let response = await axios.get("api/users/me", {
                    headers: {
                        'Authorization': 'Bearer '+ token
                    }
                });
                // console.log("api/users/me response", response);
                if (response.data.status === "success") {
                    // console.log("SET_USER", response);
                    commit('SET_USER', response.data.data);
                } else {
                    console.log("Oops! Something went wrong");
                }
            } catch (e) {
                commit('SET_TOKEN', null);
                commit('SET_USER', null);
                console.log("failed to get user date", e);
            }
        },

        signOut({ commit }) {
            // return axios.post('auth/signout').then(() => {
            commit('SET_TOKEN', null);
            commit('SET_USER', null);
            // return true;
            // })
        }


    }
}