<template>
  <div>
    <!-- wrapper -->
    <div class="wrapper">
      <Navigation />
      <!--navigation-->
      <!--end navigation-->
      <!--page-wrapper-->
      <div class="page-wrapper">
        <!--page-content-wrapper-->

        <SideNav />
        <div class="card radius-15">
          <div class="card-body" style="overflow-x: clip">
            <div>
              <!-- Tab panes -->
              <!-- <div class="tab-content"> -->
              <div v-if="loading">{{ $t("Loading...") }}</div>
              <div class="table-responsive">
                <table class="table" id="betHistoryTable" style="color: white">
                  <thead>
                    <tr>
                      <td>{{ $t("Currency") }}</td>
                      <td>{{ $t("Stake") }}</td>
                      <td>{{ $t("Direction") }}</td>
                      <td>{{ $t("Status") }}</td>
                      <td>{{ $t("Amount Won") }}</td>
                      <td>{{ $t("Created At") }}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="history in betHistory" :key="history._id">
                      <td>{{ history.currency }}</td>
                      <td>{{ history.stake }}</td>
                      <td v-if="history.direction == 1">{{ $t("High") }}</td>
                      <td v-else-if="history.direction == -1">
                        {{ $t("LOW") }}
                      </td>
                      <td>{{ history.status }}</td>
                      <td>{{ history.profit }}</td>
                      <td>{{ new Date(history.created_at) }}</td>
                    </tr>
                  </tbody>
                </table>
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end page-wrapper-->
      <!--start overlay-->
      <div class="overlay toggle-btn-mobile"></div>
      <!--end overlay-->
      <!--Start Back To Top Button-->
      <a href="javascript:;" class="back-to-top"
        ><i class="bx bxs-up-arrow-alt"></i
      ></a>
      <!--End Back To Top Button-->
      <!--footer -->
      <Footer />
      <!-- end footer -->
    </div>
    <!-- end wrapper -->
    <!--start switcher-->
    <!-- <ThemeSettings /> -->
    <!--end switcher-->
  </div>
</template>

<script>
import Navigation from "./Navigation.vue";
import SideNav from "./SideNav.vue";
import Footer from "./Footer.vue";
import ThemeSettings from "./ThemeSettings.vue";

import store from "./../store";
import axios from "axios";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default {
  name: "betHistory",
  components: {
    Navigation,
    SideNav,
    Footer,
    ThemeSettings,
  },
  data() {
    return {
      loading: false,
      betHistory: null,
      sideNavProps: { selected: "betHistory" },
    };
  },
  mounted() {
    this.getBetHistory();
  },
  methods: {
    getBetHistory() {
      let that = this;
      this.loading = true;
      // console.log("getBetHistory called");
      axios({
        method: "post",
        url: "api/game/showGameStats",
        data: {},
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // console.log("game/showGameStats ", response);
          this.loading = false;
          if (response.data.status) {
            this.betHistory = response.data.data;
            $(document).ready(function () {
              $("#betHistoryTable").DataTable({
                language: that.$t("dataTableLanguage"),
              });
            });
          } else {
            this.$router.replace({
              name: "PageNotFound",
            });
            this.error = response.data.message;
          }
        })
        .catch((error) => {
          // console.log("error", error);
          // console.log(error);
          // console.log("Error: Request failed with status code 400");
          if (error == "Error: Request failed with status code 400") {
            // console.log("inside");
            this.$router.replace({
              name: "PageNotFound",
            });
          }
          if (error == "Error: Request failed with status code 401") {
            this.$router.replace({
              name: "PageNotFound",
            });
          }

          if (error.hasOwnProperty("response")) {
            if (error.response.hasOwnProperty("status")) {
              if (error.response.status == 401) {
                this.$router.replace({
                  name: "PageNotFound",
                });
              }
            }
          }
        });
    },
  },
};
</script>

<style scoped>
</style>